import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./helpers/notifications";
import "./server";
import axios from 'axios';

import AudioVisual from "vue-audio-visual";

Vue.use(AudioVisual);

Vue.prototype.$http = axios;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app");
