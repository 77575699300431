<template>
  <header class="p-3 bg-white shadow border-b sticky top-0 z-50">
    <div class="max-w-6xl mx-auto flex justify-between items-center">
      <div class="lg:hidden">
        <button
          @click="$root.$emit('open-drawer')"
          type="button"
          class="p-2 text-primary-orange border border-primary-orange rounded"
        >
          <svg
            class="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </button>
        <MobileNavigationDrawer />
      </div>

      <div>
        <router-link to="/"
          ><img class="w-52 md:w-64" src="/img/logo.svg" alt="KnowMyRight"
        /></router-link>
      </div>
      <div class="lg:hidden">
        <button
          type="button"
          class="p-2 text-white bg-primary-orange rounded-full"
        >
          <svg
            class="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15.536 8.464a5 5 0 010 7.072m2.828-9.9a9 9 0 010 12.728M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z"
            ></path>
          </svg>
        </button>
      </div>
      <div id="main-nav" class="hidden lg:flex items-center space-x-6">
        <router-link
          to="/"
          class="py-1 text-sm font-bold text-secondary-gray uppercase tracking-wide border-b-2 border-transparent transition ease-in duration-150"
          >Home</router-link
        >
        <div class="relative">
          <button
            @click="topicsOpen = !topicsOpen"
            type="button"
            class="block space-x-2 py-1 text-sm font-bold text-secondary-gray uppercase tracking-wide border-b-2 border-transparent focus:outline-none focus:text-primary-orange focus:border-primary-orange transition ease-in duration-150"
            :class="topicRoute ? 'router-link-exact-active' : ''"
            ref="rightsButton"
          >
            <span>Rights</span> <i class="fas fa-chevron-down"></i>
          </button>
          <transition
            enter-class="transform -translate-y-5 opacity-0"
            enter-to-class="transform translate-y-0 opacity-100"
            enter-active-class="transition ease-in duration-100"
            leave-active-class="transition ease-in duration-75"
            leave-class="transform translate-y-0 opacity-100"
            leave-to-class="transform -translate-y-5 opacity-0"
            ><div
              v-if="topicsOpen"
              class="absolute top-0 mt-10 w-64 origin-top-center bg-white rounded-lg shadow-md border"
              style="left: -100%"
              ref="topicsDropdown"
            >
              <nav>
                <ul class="py-2">
                  <li v-for="(topic, i) in topics" :key="i">
                    <router-link
                      :to="'/topics/' + topic.path"
                      class="block px-3 py-2 text-gray-600 hover:text-gray-900 font-medium text-sm hover:bg-gray-200 transition ease-in duration-150"
                      >{{ topic.label }}</router-link
                    >
                  </li>
                </ul>
              </nav>
            </div></transition
          >
        </div>
        <!-- <router-link
          to="/ask"
          class="py-1 text-sm font-bold text-secondary-gray uppercase tracking-wide border-b-2 border-transparent transition ease-in duration-150"
          >Ask Us</router-link
        >
        <router-link
          to="/about"
          class="py-1 text-sm font-bold text-secondary-gray uppercase tracking-wide border-b-2 border-transparent transition ease-in duration-150"
          >About Us</router-link
        >
        <router-link
          to="/question"
          class="py-1 text-sm font-bold text-secondary-gray uppercase tracking-wide border-b-2 border-transparent transition ease-in duration-150"
          >Submit a Question</router-link
        > -->
        <LanguageDropdown />
      </div>
    </div>
  </header>
</template>

<script>
import Topics from "@/mixins/Topics";

import LanguageDropdown from "@/components/LanguageDropdown";
import MobileNavigationDrawer from "@/components/layouts/MobileNavigationDrawer";

export default {
  mixins: [Topics],
  components: {
    LanguageDropdown,
    MobileNavigationDrawer,
  },
  data: () => ({
    topicsOpen: false,
  }),
  watch: {
    topicsOpen(val) {
      if (val) {
        document.body.addEventListener("click", this.bodyClickHandler);
      } else {
        document.body.removeEventListener("click", this.bodyClickHandler);
      }
    },
    $route() {
      this.topicsOpen = false;
    },
  },
  created() {},
  computed: {
    topicRoute() {
      return this.$route.name === "Topic";
    },
  },
  methods: {
    bodyClickHandler(e) {
      if (
        this.$refs.rightsButton.contains(e.target) ||
        this.$refs.topicsDropdown.contains(e.target)
      ) {
        return;
      }

      this.topicsOpen = false;
    },
  },
  beforeDestroy() {
    document.body.removeEventListener("click", this.bodyClickHandler);
  },
};
</script>

<style></style>
