<template>
  <footer class="px-6 py-12 bg-primary-orange text-white text-sm">
    <div class="max-w-6xl mx-auto lg:flex lg:space-x-24">
      <div>
        <h2 class="text-2xl font-semibold">KnowMyRights</h2>
        <p>Empowering Our Citizens with Rights</p>
        <p class="mt-2">All Rights Reserved. &copy; {{ year }}</p>
        <!-- <div class="text-xl space-x-2 mt-6">
          <a
            class="inline-flex items-center justify-center h-10 w-10 bg-white text-primary-orange rounded-full"
            href="#"
            target="_blank"
            ><i class="fab fa-facebook-f"></i
          ></a>
          <a
            class="inline-flex items-center justify-center h-10 w-10 bg-white text-primary-orange rounded-full"
            href="#"
            target="_blank"
            ><i class="fab fa-twitter"></i
          ></a>
          <a
            class="inline-flex items-center justify-center h-10 w-10 bg-white text-primary-orange rounded-full"
            href="#"
            target="_blank"
            ><i class="fab fa-linkedin-in"></i
          ></a>
          <a
            class="inline-flex items-center justify-center h-10 w-10 bg-white text-primary-orange rounded-full"
            href="#"
            target="_blank"
            ><i class="fab fa-whatsapp"></i
          ></a>
          <a
            class="inline-flex items-center justify-center h-10 w-10 bg-white text-primary-orange rounded-full"
            href="#"
            target="_blank"
            ><i class="fab fa-instagram"></i
          ></a>
        </div> -->
      </div>
      <div class="mt-8 lg:mt-0">
        <h3 class="text-base font-semibold uppercase">Topics</h3>
        <div
          class="mt-2 grid grid-rows-6 grid-cols-2 gap-3 md:gap-x-12 grid-flow-col"
        >
          <div
            v-for="(topic, index) in topics"
            :key="`footer-topic-${index}`"
            class="col-span-1"
          >
            <router-link :to="'/topics/' + topic.path">{{
              topic.label
            }}</router-link>
          </div>
        </div>
      </div>
      <div class="mt-8 lg:mt-0">
        <h3 class="text-base font-semibold uppercase">Links</h3>
        <div
          class="mt-2 grid grid-rows-2 grid-cols-2 gap-3 md:gap-x-12 grid-flow-col"
        >
          <div class="col-span-1 w-full">
            <router-link to="/">Home</router-link>
          </div>
          <!-- <div class="col-span-1 w-full">
            <router-link to="/about">About Us</router-link>
          </div>
          <div class="col-span-1 w-full">
            <router-link to="/ask">Ask Me</router-link>
          </div> -->
          <div class="col-span-1 w-full">
            <router-link to="/">Language</router-link>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import Topics from "@/mixins/Topics";

export default {
  mixins: [Topics],
  components: {},
  data: () => ({}),
  created() {},
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
  methods: {},
};
</script>

<style></style>
