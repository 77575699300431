<template>
  <transition
    enter-class="opacity-0"
    enter-to-class="opacity-100"
    enter-active-class="transition ease-in duration-150"
    leave-active-class="transition ease-in duration-150"
    leave-to-class="opacity-0"
    leave-class="opacity-100"
    @enter="showMobileNav = true"
  >
    <div
      @click="showMobileNav = false"
      v-if="showDrawer"
      class="fixed inset-0 bg-black bg-opacity-25"
    >
      <transition
        enter-class="transform -translate-x-full opacity-0"
        enter-to-class="transform translate-x-0 opacity-100"
        enter-active-class="transition ease-out duration-150"
        leave-active-class="transition ease-out duration-150"
        leave-to-class="transform -translate-x-full opacity-0"
        leave-class="transform translate-x-0 opacity-100"
        @leave="showDrawer = false"
      >
        <div
          @click.stop
          v-if="showMobileNav"
          class="h-full w-72 bg-primary-orange text-white overflow-hidden"
        >
          <div class="h-full flex flex-col">
            <p class="text-right px-4 py-2">
              <button
                type="button"
                class="p-2 focus:outline-none"
                @click="showMobileNav = false"
              >
                <i class="fas fa-times text-2xl"></i>
              </button>
            </p>
            <nav class="px-4 flex-1 overflow-y-auto">
              <ul class="divide-y divide-secondary-off-white">
                <li>
                  <router-link
                    to="/"
                    class="block py-3 px-2 uppercase font-bold tracking-wide"
                    >Home</router-link
                  >
                </li>
                <li>
                  <button
                    type="button"
                    @click="rightsOpen = !rightsOpen"
                    class="w-full flex justify-between items-center py-3 px-2 uppercase font-bold tracking-wide"
                  >
                    <span>Rights</span>
                    <i
                      class="fas fa-chevron-down transition ease-in duration-150"
                      :class="{ 'transform rotate-180': rightsOpen }"
                    ></i>
                  </button>
                  <div v-if="rightsOpen" class="pl-2">
                    <router-link
                      v-for="(topic, i) in topics"
                      :key="`mobile-nav-topic-${i}`"
                      :to="`/topics/${topic.path}`"
                      class="block px-2 py-2"
                      >{{ topic.label }}</router-link
                    >
                  </div>
                </li>
                <!-- <li>
                  <router-link
                    to="/about"
                    class="block py-3 px-2 uppercase font-bold tracking-wide"
                    >About Us</router-link
                  >
                </li> -->
                <li>
                  <router-link
                    to="/question"
                    class="block py-3 px-2 uppercase font-bold tracking-wide"
                    >Submit a Question</router-link
                  >
                </li>
                <!-- <li>
                  <router-link
                    to="/help"
                    class="block py-3 px-2 uppercase font-bold tracking-wide"
                    >Help</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/chat"
                    class="block py-3 px-2 uppercase font-bold tracking-wide"
                    >Live Chat</router-link
                  >
                </li> -->
              </ul>
            </nav>
            <div class="relative px-4 py-6">
              <button
                @click="langOpen = !langOpen"
                type="button"
                class="w-full flex items-center justify-between text-gray-800 bg-secondary-off-white font-semibold border rounded-lg px-4 py-2 text-sm"
              >
                <div class="flex items-center space-x-4">
                  <img
                    class="h-8 w-8 rounded-full object-cover"
                    src="../../assets/images/us.svg"
                    alt="US English"
                  />
                  <span class="ml-2">US English</span>
                </div>
                <i
                  class="ml-4 fas fa-chevron-down transition ease-in duration-75"
                  :class="{ 'transform rotate-180': !langOpen }"
                ></i>
              </button>
              <transition
                enter-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                enter-active-class="transition ease-out duration-100"
                leave-active-class="transition ease-in duration-75"
                leave-to-class="transform opacity-0 scale-95"
                leave-class="transform opacity-100 scale-100"
              >
                <div
                  v-if="langOpen"
                  class="absolute origin-bottom-left inset-x-0 bottom-full px-4 -mb-4"
                  ref="langDropdown"
                >
                  <div
                    class="py-1 w-full bg-white rounded-lg shadow border"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                  >
                    <div
                      class="flex space-x-2 px-4 py-3 text-sm font-medium text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer transition ease-in duration-150"
                      role="menuitem"
                    >
                      <img
                        class="h-5 w-5 rounded-full object-cover"
                        src="../../assets/images/us.svg"
                        alt="US English"
                      />
                      <span>US English</span>
                    </div>
                    <div
                      class="flex space-x-2 px-4 py-3 text-sm font-medium text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer transition ease-in duration-150"
                      role="menuitem"
                    >
                      <img
                        class="h-5 w-5 rounded-full object-cover"
                        src="../../assets/images/in.svg"
                        alt="Hindi"
                      />
                      <span>Hindi</span>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import Topics from "@/mixins/Topics";

export default {
  mixins: [Topics],
  components: {},
  data: () => ({
    rightsOpen: false,
    showDrawer: false,
    showMobileNav: false,
    langOpen: false,
  }),
  watch: {
    $route() {
      this.showMobileNav = this.rightsOpen = false;
    },
    showMobileNav() {
      this.langOpen = false;
    },
  },
  created() {
    this.$root.$on("open-drawer", () => {
      this.showDrawer = true;
    });
  },
  computed: {},
  methods: {},
};
</script>

<style></style>
