import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  },
  {
    path: "/topics/:topic",
    name: "Topic",
    component: () =>
      import(/* webpackChunkName: "topic" */ "../views/Topic.vue"),
  },
  {
    path: "/search",
    name: "Search",
    component: () =>
      import(/* webpackChunkName: "search" */ "../views/Search.vue"),
    props: true,
  },
  {
    path: "/ask",
    name: "Ask Us",
    component: () => import(/* webpackChunkName: "ask" */ "../views/Ask.vue"),
  },
  {
    path: "/about",
    name: "About Us",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/question",
    name: "Submit a Question",
    component: () =>
      import(/* webpackChunkName: "question" */ "../views/Question.vue"),
  },
  {
    path: "/thank-you",
    name: "Thank You",
    component: () =>
      import(/* webpackChunkName: "thank-you" */ "../views/ThankYou.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
