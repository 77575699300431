import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    searching: false,
    result: false,
    searchQuery: false
  },
  mutations: {
    startSearching: state => (state.searching = true),
    stopSearching: state => (state.searching = false),
    setResult: (state, result) => (state.result = result),
  },
  actions: {
    textSearch(context) {
      context.commit("startSearching");
      fetch("/api/text-search")
        .then(res => res.json())
        .then(json => context.commit("setResult", json))
        .finally(() => context.commit("stopSearching"));
    },
  },
  modules: {},
});
