const Topics = {
  data: () => ({
    topics: [
      {
        icon: "education.svg",
        label: "Education",
        path: "education",
        bigImage: "education.png",
        smallImage: "meducation.png",
      },
      {
        icon: "personal-property.svg",
        label: "Personal Property",
        path: "personal-property",
        bigImage: "property.png",
        smallImage: "mproperty.png",
      },
      {
        icon: "government.svg",
        label: "Government Schemes",
        path: "government-schemes",
        bigImage: "government.png",
        smallImage: "mgovernment.png",
      },
      {
        icon: "hospital.svg",
        label: "Hospital",
        path: "hospital",
        bigImage: "hospital.png",
        smallImage: "mhospital.png",
      },
      {
        icon: "transportation.svg",
        label: "Transportation",
        path: "transportation",
        bigImage: "transportation.png",
        smallImage: "mtransportation.png",
      },
      {
        icon: "banking.svg",
        label: "Banking",
        path: "banking",
        bigImage: "banking.png",
        smallImage: "mbanking.png",
      },
      {
        icon: "goods-and-services.svg",
        label: "Goods & Services",
        path: "goods-and-services",
        bigImage: "goods.png",
        smallImage: "mgoods.png",
      },
      {
        icon: "stores.svg",
        label: "Stores & Malls",
        path: "stores-and-malls",
        bigImage: "stores.png",
        smallImage: "mstores.png",
      },
      {
        icon: "insurance.svg",
        label: "Insurance",
        path: "insurance",
        bigImage: "insurance.png",
        smallImage: "minsurance.png",
      },
      {
        icon: "legal-assistance.svg",
        label: "Legal Assistance",
        path: "legal-assistance",
        bigImage: "legal.png",
        smallImage: "mlegal.png",
      },
      {
        icon: "employment.svg",
        label: "Employment",
        path: "employment",
        bigImage: "employment.png",
        smallImage: "memployment.png",
      },
      {
        icon: "others.svg",
        label: "Others",
        path: "others",
        bigImage: "others.png",
        smallImage: "mothers.png",
      },
    ],
  }),
};

export default Topics;
