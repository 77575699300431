<template>
  <div id="app" class="h-full font-archivo bg-gray-100" v-cloak>
    <AppHeader />
    <main>
      <router-view />
    </main>
    <AppFooter />

    <notifications group="notifications" classes="notify" />
  </div>
</template>

<script>
import AppFooter from "@/components/layouts/AppFooter";
import AppHeader from "@/components/layouts/AppHeader";

export default {
  components: {
    AppFooter,
    AppHeader,
  },
};
</script>

<style src="@/assets/css/app.css"></style>
