<template>
  <div class="relative">
    <button
      @click="open = !open"
      class="flex items-center text-xs text-secondary-gray font-semibold border rounded-lg px-4 py-2 focus:outline-none focus:ring focus:ring-primary-orange focus:ring-opacity-50"
      ref="langButton"
    >
      <img
        class="h-5 w-5 rounded-full object-cover"
        src="@/assets/images/us.svg"
        alt="US English"
      />
      <span class="ml-2">US English</span>
      <i
        class="ml-4 fas fa-chevron-down transition ease-in duration-75"
        :class="{ 'transform rotate-180': open }"
      ></i>
    </button>
    <transition
      enter-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      enter-active-class="transition ease-out duration-100"
      leave-active-class="transition ease-in duration-75"
      leave-to-class="transform opacity-0 scale-95"
      leave-class="transform opacity-100 scale-100"
    >
      <div
        v-if="open"
        class="absolute origin-top-right right-0 mt-2 w-56 bg-white rounded-lg shadow border"
        ref="langDropdown"
      >
        <div
          class="py-1"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div
            class="flex space-x-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer transition ease-in duration-150"
            role="menuitem"
          >
            <img
              class="h-5 w-5 rounded-full object-cover"
              src="@/assets/images/us.svg"
              alt="US English"
            />
            <span>US English</span>
          </div>
          <div
            class="flex space-x-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer transition ease-in duration-150"
            role="menuitem"
          >
            <img
              class="h-5 w-5 rounded-full object-cover"
              src="@/assets/images/in.svg"
              alt="Hindi"
            />
            <span>Hindi</span>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  components: {},
  data: () => ({
    open: false,
  }),
  watch: {
    open(val) {
      if (val) {
        document.body.addEventListener("click", this.bodyClickHandler);
      } else {
        document.body.removeEventListener("click", this.bodyClickHandler);
      }
    },
  },
  computed: {},
  methods: {
    bodyClickHandler(e) {
      if (
        this.$refs.langDropdown.contains(e.target) ||
        this.$refs.langButton.contains(e.target)
      ) {
        return;
      }

      this.open = false;
    },
  },
};
</script>

<style></style>
